import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';
import './HowWeWork.scss'
import { Link } from 'react-router-dom';
import { useRef, useEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// Регистрируем плагин ScrollTrigger
gsap.registerPlugin(ScrollTrigger);

function HowWeWork({ content }) {
   const { t, i18n } = useTranslation();
   const listRef = useRef(); // Создаём массив ref'ов для блоков

   useEffect(() => {
      const thisList = listRef?.current
      const listChildren = Array.from(thisList?.children) || []


      listChildren.forEach(item => {
         gsap.set(item, { opacity: .3 })
         const animation = gsap.timeline().to(item, { opacity: 1 })
         ScrollTrigger.create({
            trigger: item,
            start: "top bottom",
            end: "top top",
            animation: animation,
            scrub: 1,
            once: true,
            duration: 0,
         })
      })
   }, [content]);

   return (
      <div className='development_page_wrap_work'>
         <h2 className="development_page_wrap_work-title">
            {t('developmentPage.Принципы работы.Заголовок')}
         </h2>
         <div className="development_page_wrap_work_list_block">
            <div className="development_page_wrap_work_list" ref={listRef}>
               {content?.map((item, index) => (
                  <div className="development_page_wrap_work_list_it row" key={nanoid(4)}>
                     <div className="col-md-1">
                        <div className="development_page_wrap_work_list_it-num">
                           [{index + 1}]
                        </div>
                     </div>
                     <div className="col-md-2">
                        <h3 className="development_page_wrap_work_list_it-title">
                           {i18n.language === 'ru-RU' || i18n.language === 'ru' ? item?.title : item?.titleEn}
                        </h3>
                     </div>
                     <div className="offset-md-1 col-md-4">
                        <div className="development_page_wrap_work_list_it-text" dangerouslySetInnerHTML={{ __html: i18n.language === 'ru-RU' || i18n.language === 'ru' ? item?.text : item?.textEn }} />
                     </div>
                  </div>
               ))}
            </div>
            <Link to={'/'} className='development_page_wrap_work_list_block-btn'>
               {t('developmentPage.Принципы работы.Кнопка')}
            </Link>
         </div>
      </div>
   );
}

export default HowWeWork;