import { useEffect } from 'react';

const RecaptchaScript = () => {
   useEffect(() => {
      const script = document.createElement('script');
      script.src = 'https://www.google.com/recaptcha/api.js?render=6LcN3Z4qAAAAAMKcPQ94vXP4GOYSu-0jOTKcHkEf';
      script.async = true;
      document.body.appendChild(script);

      return () => {
         document.body.removeChild(script);
      };
   }, []);

   return null;
};

export default RecaptchaScript;