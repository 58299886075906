import { useTranslation } from 'react-i18next';

function FooterFormError() {
   const { t } = useTranslation();
   return (
      <div className="form_response form_error">
         <div className="form_response-title form_error-title">{t('footer.form.форма_ошибка_заголовок')}</div>
         <div className="form_response-text form_error-text">
            <p>{t('footer.form.форма_ошибка_текст')}</p>
         </div>
      </div>
   );
}

export default FooterFormError;