import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css/navigation';
import { nanoid } from 'nanoid';
import CaseBtn from '../../Cases/CasesComponent/CaseBtn';
import './DevelopmentReviews.scss'
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

function DevelopmentReviews({ content }) {
   const { t, i18n } = useTranslation();
   const isTablet = useMediaQuery({ query: '(max-width: 1200px)' });

   return (
      <div className='development_page_wrap_reviews'>
         <h2 className="development_page_wrap_reviews-title">{t('developmentPage.ОтзывыЗаголовок')}</h2>
         <div className="development_page_wrap_reviews_swiper row">
            <div className="col-md-7">
               <Swiper
                  modules={[Navigation]}
                  className='development_page_wrap_reviews_swiper_sw'
                  speed={600}
                  navigation={{
                     nextEl: '.development_page_wrap_reviews_swiper_btns-next',
                     prevEl: '.development_page_wrap_reviews_swiper_btns-prev',
                  }}
                  slidesPerView={1}
                  spaceBetween={20}
               >
                  {content?.map(item => (
                     <SwiperSlide className='development_page_wrap_reviews_swiper_sw_item' key={nanoid(4)}>
                        <div className="development_page_wrap_reviews_swiper_sw_item_author order-xl-1 order-2">
                           <div className="development_page_wrap_reviews_swiper_sw_item_author-avatar">
                              <img src={item?.author?.imageLink} alt={i18n.language === 'ru-RU' || i18n.language === 'ru' ? item?.author?.name : item?.author?.nameEn} />
                           </div>
                           <h3 className="development_page_wrap_reviews_swiper_sw_item_author-name" dangerouslySetInnerHTML={{ __html: i18n.language === 'ru-RU' || i18n.language === 'ru' ? item?.author?.name : item?.author?.nameEn }} />
                        </div>
                        <div className="development_page_wrap_reviews_swiper_sw_item_content order-xl-2 order-1">
                           <div className="development_page_wrap_reviews_swiper_sw_item_content-text">
                              <p>
                                 {i18n.language === 'ru-RU' || i18n.language === 'ru' ? item?.textReview : item?.textReviewEn}
                              </p>
                           </div>
                           {!isTablet &&
                              <CaseBtn
                                 link={item?.caseLink}
                                 className={'development_page_wrap_reviews_swiper_sw_item_content-btn'}
                                 targetLink={true}>{t('developmentPage.ПосмотретьПроект')}</CaseBtn>
                           }
                        </div>
                        {isTablet &&
                           <CaseBtn
                              link={item?.caseLink}
                              className={'development_page_wrap_reviews_swiper_sw_item-btn order-3'}
                              targetLink={true}>{t('developmentPage.ПосмотретьПроект')}</CaseBtn>
                        }
                     </SwiperSlide>
                  ))}
               </Swiper>
            </div>
            <div className="col-md-1">
               <div className="development_page_wrap_reviews_swiper_btns">
                  <div className="swiper-button-prev development_page_wrap_reviews_swiper_btns-prev">
                     <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                        <g>
                           <rect x="50" y="50" width="50" height="50" rx="25" transform="rotate(-180 50 50)" fill="#ECECEC" />
                           <path d="M32 25L18 25M18 25L23.8333 31M18 25L23.8333 19" stroke="#8A8A8A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </g>
                     </svg>
                  </div>
                  <div className="swiper-button-next development_page_wrap_reviews_swiper_btns-next">
                     <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                        <g>
                           <rect width="50" height="50" rx="25" fill="#ECECEC" />
                           <path d="M18 25H32M32 25L26.1667 19M32 25L26.1667 31" stroke="#8A8A8A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </g>
                     </svg>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}

export default DevelopmentReviews;