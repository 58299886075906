
import './Head.scss'
import { useTranslation } from 'react-i18next';

function Head() {

   const { t } = useTranslation();

   return (
      <div className="development_page_wrap_head row">
         <div className="col-xl-3">
            <h1 className="development_page_wrap_head-title page_title">{t('developmentPage.head.Заголовок')}</h1>
         </div>
         <div className="col-xl-3">
            <div className="development_page_wrap_head-text">
               <p>
                  {t('developmentPage.head.Текст')}
               </p>
            </div>
         </div>
      </div>
   );
}

export default Head;