import { useTranslation } from 'react-i18next';

function FooterFormLoading() {
   const { t } = useTranslation();
   return (
      <div className="form_response form_loading">
         <div className="form_loading-text">
            {t('footer.form.форма_отправка')}
         </div>
         <span class="loader"></span>
      </div>
   );
}

export default FooterFormLoading;