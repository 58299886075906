import { useTranslation } from 'react-i18next';
import './ReadyStart.scss'

function ReadyStart() {

   const { t } = useTranslation();
   return (
      <div className="ready_start">
         <div className="ready_start-text" dangerouslySetInnerHTML={{ __html: t('developmentPage.ТексНаСинейПлашке') }} />
         <div className="header_wrap_contact_us ready_start-btn">
            <span className='header_wrap_contact_us-icon'>
               <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                  <circle cx="11" cy="11" r="3" fill="#FFF" />
                  <g filter="url(#filter0_f_1387_3373)">
                     <circle cx="11" cy="11" r="6" stroke="#FFF" />
                  </g>
                  <defs>
                     <filter id="filter0_f_1387_3373" x="0.5" y="0.5" width="21" height="21" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_1387_3373" />
                     </filter>
                  </defs>
               </svg>
            </span>
            <a href="https://wa.link/d4en74" className="header_wrap_contact_us-it" target='_blank' rel="nofollow noreferrer noopener" aria-label="Whatsapp" title="Whatsapp">
               {t('header.Связаться с нами')}
            </a>
         </div>
         <div className="ready_start-image left">
            <picture>
               <source srcSet="./img/development/top-image-mobile.png" media="(max-width: 767px)" />
               <source srcSet="./img/development/left-image-tablet.png" media="(max-width: 1199px)" />
               <img src="./img/development/left-image.png" alt="" />
            </picture>
         </div>
         <div className="ready_start-image right">
            <picture>
               <source srcSet="./img/development/bot-image-mobile.png" media="(max-width: 767px)" />
               <source srcSet="./img/development/right-image-tablet.png" media="(max-width: 1199px)" />
               <img src="./img/development/right-image.png" alt="" />
            </picture>
         </div>
         <div className="ready_start-elipse"></div>
      </div>
   );
}

export default ReadyStart;