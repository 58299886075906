import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./BreadCrumbs.scss";
import { useTranslation } from "react-i18next";

function BreadCrumbs({ container }) {
   const location = useLocation();
   const { t } = useTranslation();

   // Разбиваем путь на части
   const pathParts = location.pathname.split("/").filter(part => part);

   // Создаем массив хлебных крошек
   const breadcrumbs = pathParts.map((part, index) => {
      const path = `/${pathParts.slice(0, index + 1).join("/")}`;
      return {
         label: part,
         path: path
      };
   });

   return (
      <div className="breadcrumbs">
         <div className={`${container ? "container" : ""}`}>
            <ul className="breadcrumbs_wrap">
               <li><Link to="/">{t(`breadcrumbs.Главная`)}</Link></li>
               {breadcrumbs.map((breadcrumb, index) => (
                  <React.Fragment key={breadcrumb.path}>
                     <li>
                        {index === breadcrumbs.length - 1 ? (
                           <span>{t(`breadcrumbs.${breadcrumb.label}`)}</span>
                        ) : (
                           <>
                              <Link to={breadcrumb.path}>{t(`breadcrumbs.${breadcrumb.label}`)}</Link>
                           </>
                        )}
                     </li>
                  </React.Fragment>
               ))}
            </ul>
         </div>
      </div>
   );
}

export default BreadCrumbs;