import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { isValidPhoneNumber } from 'libphonenumber-js';
import RecaptchaScript from '../../utilities/RecaptchaScript/RecaptchaScript';
import InvisibleRecaptcha from '../../utilities/RecaptchaScript/InvisibleRecaptcha';
import FooterFormError from './FooterFormError';
import FooterFormSucsess from './FooterFormSucsess';
import FooterFormLoading from './FooterFormLoading';

function FooterForm() {
   const { t } = useTranslation();
   const [formData, setFormData] = useState({
      name: '',
      phone: '',
      message: '',
      consent: false
   });
   const [isPhoneTouched, setIsPhoneTouched] = useState(false);
   const [recaptchaToken, setRecaptchaToken] = useState(null);
   const [formResponse, setFormResponse] = useState('');

   const handleChange = (e) => {
      const { name, value, type, checked } = e.target;
      setFormData({
         ...formData,
         [name]: type === 'checkbox' ? checked : value,
      });
   };

   const handlePhoneChange = (value) => {
      setFormData({
         ...formData,
         phone: value
      });
      setIsPhoneTouched(true);
   };

   const isFormValid = () => {
      return (
         formData.name.trim() !== '' &&
         formData.message.trim() !== '' &&
         formData.phone && isValidPhoneNumber(formData.phone)
      );
   };

   const clearFormResponseAfterDelay = () => {
      setTimeout(() => {
         setFormResponse('');
      }, 6000);
   };

   const submitHandler = (event) => {
      event.preventDefault();
      if (!recaptchaToken) {
         console.log('reCAPTCHA token is missing');
         return;
      }

      setFormResponse('loading');

      fetch("/send.php", {
         method: "POST",
         headers: {
            'Content-Type': 'application/json'
         },
         body: JSON.stringify({ ...formData, recaptchaToken })
      })
         .then(response => response.json())
         .then(json => {
            if (json.Result === "Success") {
               setFormResponse('sucsess');
            } else {
               setFormResponse('error');
            }
            clearFormResponseAfterDelay()
         })
         .catch(error => {
            console.error('Error:', error);
            setFormResponse('error');
            clearFormResponseAfterDelay()
         });
   };

   return (
      <>
         <RecaptchaScript />
         <form action='#' onSubmit={submitHandler} method="post">
            <div className="footer_form row">
               <div className="footer_form_left">
                  <div className="footer_form-input">
                     <input
                        type="text"
                        name="name"
                        placeholder={t('footer.Ваше имя')}
                        required
                        value={formData.name}
                        onChange={handleChange}
                     />
                     <div className="correct_icon">
                     </div>
                  </div>
                  <div className="footer_form-input tel">
                     <PhoneInput
                        placeholder={t('footer.Контактный телефон')}
                        value={formData.phone}
                        onChange={handlePhoneChange}
                        international
                        withCountryCallingCode
                        required
                        className={isPhoneTouched ? formData.phone && isValidPhoneNumber(formData.phone) ? "correct" : "incorrect" : ''}
                     />
                     <div className={`correct_icon ${isPhoneTouched ? formData.phone && isValidPhoneNumber(formData.phone) ? "correct" : "incorrect" : ''}`}>
                     </div>
                  </div>
               </div>
               <div className="footer_form_right">
                  <div className="footer_form-area">
                     <textarea
                        name="message"
                        placeholder={t('footer.Пара слов о вашем проекте')}
                        required
                        value={formData.message}
                        onChange={handleChange}
                     ></textarea>
                     <div className="correct_icon">
                     </div>
                  </div>
               </div>
               <div className="footer_form-check">
                  <input
                     type="checkbox"
                     className="custom-checkbox"
                     id="get_touch-check"
                     name="consent"
                     required
                     checked={formData.consent}
                     onChange={handleChange}
                  />
                  <label htmlFor="get_touch-check">{t('footer.Чекбокс')}</label>
               </div>
               <div className="footer_form-btn">
                  <input type="submit" value={t('footer.Отправить')} disabled={!isFormValid()} />
               </div>
            </div>
            <InvisibleRecaptcha onVerify={setRecaptchaToken} />
            {formResponse === 'loading' &&
               <FooterFormLoading />
            }
            {formResponse === 'error' &&
               <FooterFormError />
            }
            {formResponse === 'sucsess' &&
               <FooterFormSucsess />
            }
         </form>
      </>
   );
}

export default FooterForm;