import { useTranslation } from 'react-i18next';

function FooterFormSucsess() {
   const { t } = useTranslation();
   return (
      <div className="form_response form_sucsess">
         <div className="form_response-title form_sucsess-title">
            {t('footer.form.форма_успех_заголовок')}
         </div>
         <div className="form_response-text form_sucsess-text">
            <p dangerouslySetInnerHTML={{ __html: t('footer.form.форма_успех_текст') }} />
         </div>
      </div>
   );
}

export default FooterFormSucsess;