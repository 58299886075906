import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

function CaseBtn({ link, otherPage, className, children, targetLink }) {

   const { t } = useTranslation();

   return (
      link ? (
         <div className={`case_btn ${otherPage ? 'other_page' : ''} ${className}`}>
            <div className="case_btn-icon icon">
               <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                  <circle cx="11" cy="11" r="3" fill="#2898FF" />
                  <g filter="url(#filter0_f_1317_2116)">
                     <circle cx="11" cy="11" r="6" stroke="#2898FF" />
                  </g>
                  <defs>
                     <filter id="filter0_f_1317_2116" x="0.5" y="0.5" width="21" height="21" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_1317_2116" />
                     </filter>
                  </defs>
               </svg>
            </div>
            <Link to={link} className="case_btn-btn" target={!targetLink ? '_self' : '_blank'} rel={!targetLink ? '' : 'nofollow noindex noreferrer'}>
               {children ? (
                  children
               ) : (
                  t("cases.Кнопка кейса")
               )}
            </Link>
         </div>
      ) : (
         ''
      )

   );
}

export default CaseBtn;