import DevelopmentReviews from "../components/Development/DevelopmentReviews/DevelopmentReviews";
import Head from "../components/Development/Head/Head";
import HowWeWork from "../components/Development/HowWeWork/HowWeWork";
import OurTech from "../components/Development/OurTech/OurTech";
import ReadyStart from "../components/Development/ReadyStart/ReadyStart";
import BreadCrumbs from "../components/utilities/BreadCrumbs/BreadCrumbs";
import { useEffect, useState } from "react";
import '../components/Development/style/style.scss'

function Development() {
   const [dataContent, setDataContent] = useState([])

   // Получаем кейсы
   useEffect(() => {
      const fetchCases = async () => {
         try {
            const response = await fetch('/data/development.json');
            if (!response.ok) {
               throw new Error('Не удалось отправить запрос');
            }
            const data = await response.json();
            setDataContent(data[0]);

         } catch (error) {
            console.error('Не удалось получить кейсы: ', error);
         }
      };

      fetchCases();
   }, []);
   return (
      <div className="development_page">
         <div className="container">
            <BreadCrumbs container={false} />
            <div className="development_page_wrap">
               <Head />
               <OurTech />
               <HowWeWork content={dataContent?.howWeWork} />
               <DevelopmentReviews content={dataContent?.reviews} />
               <ReadyStart />
            </div>
         </div>
      </div>
   );
}

export default Development;